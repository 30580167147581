@charset "utf-8";

@mixin query($size) {
    @media all and (max-width: $size + px) {
        @content;
    }
}

@mixin minquery($size) {
    @media all and (min-width: $size + px) {
        @content;
    }
}

@mixin minmaxquery($size, $size1) {
    @media only screen and (min-width: $size + px) and (max-width: $size1 + px) {
        @content;
    }
}

$cl: #008fca;
$cltext: #333;
$cltitle: #008fca;
$cltitlesub: #00beca;
$sp: 768;
$pc: 769;
//Container Define
$container: 1200;
$container-large: 1240;
$q960: 960;
$q768: 768; 
$q520: 520;
$q414: 414;
$q320: 320;

//Preload
$preload: true; 

//image dir define.
$dir: "../images/";
$dir2: "../../images/";

//color define:
$white: #fff;
$black: #000;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #F7931E !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;