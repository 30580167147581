@import "../components/mixins";

.footer{
	background: #154b88;
	padding: 70px 0;
	@include query($sp){
		padding: 40px 0 20px;
	}
	&-info{
		@include flexbox(flex-start, flex-start);
		@include query($sp){
			flex-wrap: wrap;
		}
		ul{
			margin-right: 200px;
			&:last-child{
				margin-right: 0;
			}
			@include query($sp){
				margin-right: 0;
			}
		}
		li.ahover{
			margin-bottom: 30px;
			@include query($sp){
				margin-bottom: 20px;
			}
			a{
				color: #fff;
				font-size: 16px;
				&::after{
					left: 0;
					background-color: #fff;
				}
			}
		}
	}
	&_logo{
		flex: 0 0 390px;
		@include query($sp){
			flex: 0 0 100%;
			margin-bottom: 35px;
		}
	}
	&_nav{
		@include flexbox(flex-start, flex-start);
		@include query($sp){
			flex: 0 0 100%;
			flex-wrap: wrap;
		}
	}
	&-bottom {
		margin-top: 50px;
		@include query($sp){
			margin-top: 30px;
		}
		p{
			font-size: 16px;
			text-align: center;
			opacity: 0.27;
			color: #fff;
			@include query($sp){
				font-size: 14px;
			}
		}
	}
	.backtop {
		width:100%;
		max-width: 100%;
		position:fixed; 
		z-index:8;
		bottom: 22px;
		right: 0;
		&-icon {
			cursor:pointer;
			display:block;
			position:absolute; 
			bottom:0; 
			right:25px; 
			zoom: 1;
			text-align: right;
			z-index: 8;
			img{
				width: 45px;
				height: 45px;
			}
			@include query($sp){
				right: 3%;
			}
		}
	}
}