@charset "utf-8";
//RESET
@import "../scss/reset/normalize2";
@import "../scss/reset/other";

//FONT
@import "fonts/font";

//MIXINS
@import "../scss/components/mixins";

//ANIMATION
@import "../scss/animate/animate";

//If EFFECT
@import "../scss/components/effect";
@import "../scss/function/function";

//FIX LAYOUT
@import "../scss/components/IE";
// @import "../scss/components/fixlayout";

//If PRELOAD
@import "../scss/components/preload";

//LAYOUT
@import "../scss/layout/header";
@import "../scss/layout/nav";
@import "../scss/layout/aside";
@import "../scss/layout/footer";

//COMPONENT
@import "../scss/components/btn";
  
.fss{@include flexbox(flex-start,flex-start)}
.fsc{@include flexbox(flex-start,center)}
.fcc{@include flexbox(center,center)}
.fsp{@include flexbox(space-between,flex-start)}
.fwb{font-weight: bold;}
.fwn{font-weight: normal;}
.fwl{font-weight: 300;}
.ctboth{@include centerboth()}
.ctver{@include centerver()}
.cthor{@include centerhor();}
.row {@include flexbox(flex-start, flex-start); flex-wrap:wrap;}
.relative {position: relative;}
.lsp05 {letter-spacing: 0.5px;}
.lsp1 {letter-spacing: 1px;}
.lsp15 {letter-spacing: 1.5px;}
.lsp2 {letter-spacing: 2px;}

body {
    font-family: 'Noto Sans JP', sans-serif;
    font-size:14px;
    color: $cltext;
    letter-spacing: 0.5px;
    .maincl{
        color: $cl;
    }
    .fontnoto{
        font-family: 'Noto Sans JP', sans-serif;
    }
    .fontmin{
        @include mincho;
    }
    .fontosw{
        font-family: 'Oswald', sans-serif !important;
        font-weight: 900;
    }
    p{
        font-size: 14px;
        line-height: 1.8;
        font-family: 'Noto Sans JP', sans-serif;
        color: $cltext;
        letter-spacing: 0.5px;
        @include minquery($pc){ 
            font-size: 16px;
        }
    }
    a{
        color: $cltext;
        line-height: normal;
    }
    img, iframe {
        max-width: 100%;
        line-height: 0;
    }
    .img{
        overflow: hidden;
        line-height: 0;
        img{
            transform: scale(1);
            transition-duration: 4s;
            -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            -o-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            -webkit-transition-property: -webkit-transform;
            -o-transition-property: transform;
            transition-property: transform, -webkit-transform;
        }
    }
}

.flexbox{
    @include flexbox(space-between,center);
    flex-wrap: wrap;
    &_start{
        justify-content: flex-start;
    }
    &_end{
        justify-content: flex-end;
    }
    &_center{
        justify-content: center;
    }
}

//FIX LAYOUT SCROLL
html{
    @media all and (min-width: $pc+px) and (max-width: $container+px){
        overflow-x: scroll;
    }
}

body{
    @media all and (min-width: $pc+px) and (max-width: $container+px){
        min-width: $container+px;
    }
}

.container {
    max-width: $container+px;
    margin: 0 auto;
    width: 100%;
    @media all and (min-width: $pc+px) and (max-width: $container+px){
        min-width: $container+px;
    }
    @include query($sp) {
        padding: 0 3%;
    }
    @include minquery($pc){
        padding: 0 30px;
    }
}

#mainmv{
    background: url(../images/banner.jpg) no-repeat center center;
    background-size: cover;
    min-height: 717px;
    @include query($sp){
        // min-height: inherit;
        // padding-bottom: 100px;
    }
    .container{
        position: relative;
        min-height: 717px;
        @include flexbox(flex-start, center);
        @include query($sp){
            align-items: flex-start;
            min-height: inherit;
            flex-wrap: wrap;
        }
    }
    .content{
        position: relative;
        z-index: 8;
        @include query($sp){
            padding-top: 20px;   
            ul{
                margin-bottom: 20px;
            }
        }
        li{
            background: #fff;
            border-radius: 100%;
            width: 174px;
            @include flexbox(center, center);
            @include query($sp){
                flex: 0 0 30%;
                max-width: 120px;
                padding: 10px;
                img{
                    height: 34px;
                }
            }
        }
        p{
            margin: 30px 0;
            font-size: 20px;
            color: #fff;
            span{
                font-weight: 900;
                background: -webkit-gradient(linear,left bottom,left top,color-stop(50%,#ff7e00),color-stop(50%,transparent));
                background: -webkit-linear-gradient(bottom,#ff7e00 50%,transparent 50%);
                background: -o-linear-gradient(bottom,#ff7e00 50%,transparent 50%);
                background: linear-gradient(0deg,#ff7e00 50%,transparent 50%);
            }
            @include query($sp){
                margin: 20px 0;
            }
            @include query(520){
                font-size: 4vw;
            }
        }
    }
    .img{
        @include minquery($pc){
            position: absolute;
            z-index: 1;
            right: -70px;
            bottom: 0;
        }
    }
}

.titpage{
    margin-bottom: 60px;
    @include query($sp){
        margin-bottom: 30px;       
    }
    h2{
        text-align: center;
        position: relative;
        font-size: 48px;
        font-weight: 900;
        line-height: 1;
        @include flexbox(center, center);
        flex-wrap: wrap;
        @include query($sp){
            font-size: 32px;
            line-height: 1.4;
        }
        @include query(375){
            font-size: 30px;
        }
    }
    span.fontosw{
        font-size: 56px;
        font-weight: 900;
        line-height: 1;
        margin-right: 10px;
        @include query($sp){
            font-size: 40px;
            margin-right: 0;
        }
    }
    small{
        display: block;
        flex: 0 0 100%;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 10px;
        @include query($sp){
            font-size: 16px;
            line-height: 1.4;
        }
    }
    img.label{
        position: absolute;
        right: 50%;
        margin-right: -450px;
        @include query($sp){
            right: 0;
            margin-right: 0;
            width: 70px;
            top: 25px;
        }
    }
    h3.sub{
        line-height: 1;
        text-align: center;
        padding-top: 15px;
        margin-top: 25px;
        background: -webkit-linear-gradient(left, #0090ca , #00beca);
        background: -o-linear-gradient(right, #0090ca, #00beca);
        background: -moz-linear-gradient(right, #0090ca, #00beca);
        background: linear-gradient(to right, #0090ca , #00beca); 
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: 'Oswald', sans-serif !important;
        font-size: 30px;
        font-weight: 300;
        position: relative;
        &::after{
            position: absolute;
            background: url(../images/line.png) no-repeat top center;
            background-size: 90px;
            width: 90px;
            height: 2px;
            content: '';
            left: 50%;
            margin-left: -45px;
            top: 0;
        }
        @include query($sp){
            margin-top: 20px;
        }
    }
}

.section{
    padding: 100px 0;
    position: relative;
    @include query($sp){
        padding: 50px 0;
    }
}

.about{
    background: -webkit-gradient(linear,left bottom,left top,color-stop(80%,#c7eff9),color-stop(50%,transparent));
    background: -webkit-linear-gradient(bottom,#c7eff9 80%,transparent 50%);
    background: -o-linear-gradient(bottom,#c7eff9 80%,transparent 50%);
    background: linear-gradient(0deg,#c7eff9 80%,transparent 50%);
    &::before{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 420px;
        background: #f0fcff;
    }
    .content{
        background: #fff;
        padding: 60px;
        border-radius: 30px;
        box-shadow: 5px 0 16px rgba($color: #127bb6, $alpha: 0.1);
        position: relative;
        z-index: 9;
        @include query($sp){
            padding: 15px 30px;
            border-radius: 15px;
        }
        .slick-slider2{
            @include minquery($pc){
                @include flexbox(space-between, flex-start);
                flex-wrap: wrap;
                .item{
                    flex: 0 0 49.5%;
                    margin-bottom: 1%;
                    &:nth-child(3),
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.client{
    background: url(../images/bggra01.jpg) repeat-y center top;
    .content{
        @include query($sp){
            padding: 0 30px;
        }
        .slider{
            margin-bottom: 55px;
            @include query($sp){
                margin-bottom: 30px;
            }
        }
        .box{
            background: #fff;
            border-radius: 15px;
            box-shadow: 5px 0 16px rgba($color: #127bb6, $alpha: 0.1);
            padding: 20px;
        }
        .slick-slider{
            @include minquery($pc){
                @include flexbox(space-between, flex-start);
                flex-wrap: wrap;
                .box{
                    border-radius: 25px;
                    max-width: 350px;
                    flex: 0 0 31%;
                    padding: 30px;
                }
            }
        }
        .icon{
            text-align: center;
            @include query($sp){
                img{
                    margin: 0 auto;
                }
            }
        }
        h4{
            font-size: 24px;
            font-weight: 700;
            margin: 15px 0;
            @include query($sp){
                font-size: 20px;
            }
        }
    }
}

.voice{
    background: url(../images/bgvoide.jpg), url(../images/bggra02.jpg);
    background-position: center top, center bottom;
    background-repeat: no-repeat, repeat-y;
    background-size: contain, cover;
    @include query($sp){
        padding-top: 40px;
        background-size: 200%, cover;
    }
    .content{
        background: #fff;
        border-radius: 25px;
        box-shadow: 5px 0 16px rgba($color: #127bb6, $alpha: 0.1);
        padding: 45px 30px;
        margin-bottom: 40px;
        @include query($sp){
            border-radius: 15px;
            padding: 25px 30px;
            margin-bottom: 30px;
        }
    }
    .slider{
        @include minquery($pc){
            position: relative;
            &:after{
                position: absolute;
                left: 50%;
                top: 0;
                content: '';
                background: #eee;
                width: 1px;
                height: 100%;
            }
        }
    }
    .slick-slider{
        @include minquery($pc){
            @include flexbox(space-between, flex-start);
            flex-wrap: wrap;
            .box{
                flex: 0 0 50%;
                max-width: 500px;
                padding: 10px 0;
            }
        }
        @include query($sp){
            .slick-prev, .slick-next{
                top: 70px;
            }
        }
    }
    .icon{
        text-align: center;
        @include query($sp){
            img{
                margin: 0 auto;
            }
        }
    }
    h4{
        color: #008fca;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        background: #eefafb;
        border-radius: 50px;
        padding: 15px 0;
        margin-bottom: 40px;
        @include query($sp){
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
    h5{
        font-size: 24px;
        font-weight: 700;
        margin-top: 30px;
        margin-bottom: 20px;
        @include query($sp){
            font-size: 18px;
            margin: 15px 0;
        }
    }
    p{
        text-align: justify;
    }
}

.pickup{
    margin-top: -24px;
    background: url(../images/bgbrn.png) no-repeat center top;
    height: 370px;
    padding-top: 80px;
    @include query($sp){
        background: rgb(0,143,202);
        background: linear-gradient(90deg, rgba(0,143,202,1) 0%, rgba(0,190,202,1) 100%); 
        padding: 30px 0 0;
        height: auto;
    }
    .container{
        position: relative;
        @include flexbox(flex-start, center);
        flex-wrap: wrap;
        .img{
            position: relative;
            right: -4%;
        }
    }
    h2{
        font-size: 48px;
        font-weight: 900;
        color: #fff;
        line-height: 1.5;
        padding-left: 30px;
        span{
            font-size: 56px;
            font-weight: 900;
            line-height: 1;
        }
        @include query($sp){
            font-size: 32px;
            padding-left: 0;
            span{
                font-size: 40px;
            }
        }
        @include query(520){
            font-size: 6.1vw;
            span{
                font-size: 7.1vw;
            }
        }
    }
    .label{
        position: absolute;
        right: 300px;
        top: 0;
        @include query($sp){
            top: 35%;
            right: inherit;
            left: 10%;
            img{
                width: 120px;
            }
        }
        @include query(375){
            img{
                width: 100px;
            }
        }
    }
}

.btnpage{
    a{
        border-radius: 50px;
        background: rgb(0,190,202);
        background: linear-gradient(90deg, rgba(0,190,202,1) 0%, rgba(0,190,202,1) 100%); 
    }
    span{
        font-size: 16px;
        font-weight: 500;
    }
}

.faq{
    dl{
        margin-bottom: 65px;
        &:last-child{
            margin-bottom: 0;
        }
        @include query($sp){
            margin-bottom: 40px;
        }
        dt{
            font-size: 24px;
            font-weight: 700;
            background: url(../images/iconq.png) no-repeat left top;
            padding-left: 110px;
            margin-bottom: 20px;
            min-height: 90px;
            padding-top: 8px;
            @include query($sp){
                font-size: 16px;
                background-size: 50px;
                padding-left: 60px;
                padding-right: 20px;
                position: relative;
                cursor: pointer;
                &::after{
                    position: absolute;
                    content: '';
                    background: url(../images/arr-gray.png) no-repeat;
                    width: 12px;
                    height: 18px;
                    right: 0;
                    top: 50%;
                    margin-top: -9px;
                    transform: rotate(90deg);
                    transition: all .3s;
                }
                &.open{
                    &::after{
                        transform: rotate(275deg);
                    }
                }
            }
        }
        dd{
            font-size: 16px;
            @include query($sp){
                font-size: 14px;
            }
        }
    }
    .acr_con{
        @include query($sp){
            display: none;
        }
    }
}

.contact{
    background: url(../images/bggra01.jpg) repeat-y center top;
    .table_template{
        max-width: 690px;
        margin: 0 auto;
        a{
            color: #ff7e00;
        }
    }
    table{
        width: 100%;
        margin: 50px 0;
        @include query($sp){
            margin: 30px 0;
        }
        th{
            font-size: 20px;
            font-weight: 700;
            width: 230px;
            text-align: left;
            vertical-align: middle;
            padding: 10px 0;
            position: relative;
            @include query($sp){
                display: block;
                width: 100%;
                font-size: 16px;
                padding-bottom: 0;
            }
            &.vertitop{
                vertical-align: top;
                padding-top: 20px;
            }
            .required, .nonerequired {
				display: table;
				float: right;
				margin-right: 10px;
				&:before {
					content: "必須";
					font-size: 16px;
					font-weight: 500;
					display: inline-block;
					color: #fff;
					padding: 2px 10px;
					background: #ff7e00;
					@include query($q768){
						font-size: 14px;
					}
				}
            }
            .required{
				&:before {
					content: "必須";
					background: #ff7e00;
				}
			}
            .nonerequired{
                &:before {
					content: "";
					background: none;
				}
            }
        }
        td{
            padding: 10px 0;
            padding-left: 30px;
            @include query($sp){
                padding: 10px 0;
                display: block;
                width: 100%;
            }
            input{
                font-size: 20px;
                padding: 10px;
                border-radius: 10px;
                border: 2px solid #ccc;
                width: 100%;
                @include query($sp){
                    font-size: 16px;
                }
            }
            textarea{
                width: 100%;
                padding: 10px;
                border-radius: 10px;
                border: 2px solid #ccc;
                font-size: 20px;
                height: 200px;
                @include query($sp){
                    font-size: 16px;
                    height: 150px;
                }
            }
        }
    }
    .form_submit{
        @include flexbox(center, center);
        flex-wrap: wrap;
        .lable{
            text-align: center;
            margin: 0 auto;
            flex: 0 0 100%;
            max-width: 470px;
            @include query($sp){
                text-align: center;
            }
        }
        label{
            font-size: 18px;
            font-weight: 700;
            line-height: 2;
            @include query($sp){
                font-size: 16px;
            }
        }
        a{
            color: #ff7e00;
            text-decoration: underline;
            font-size: 18px;
            @include query($sp){
                font-size: 16px;
            }
        }
        &_btn{
            flex: 0 0 100%;
            max-width: 470px;
            margin-bottom: 100px;
            @include query($sp){
                margin-bottom: 70px;
            }
            button{
                width: 100%;
                background: #ff7e00;
                border-radius: 50px;
                padding: 25px 0;
                &::after{
                    height: 80px;
                }
            }
            span{
                display: block;
                width: 100%;
                font-size: 30px;
                font-weight: 900;
                &::before{
                    display: none;
                }
                @include query($sp){
                    font-size: 20px;
                }
            }
        }
    }
    .contentacr{
        margin-bottom: 40px;
        background: #fff;
        border-radius: 25px;
        box-shadow: 5px 0 16px rgba($color: #127bb6, $alpha: 0.1);
        .acr_title{
            background: #00beca;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            cursor: pointer;
            h2{
                position: relative;
                span{
                    @include iconarrow($cl: #fff, $w: 4px);
                    margin-top: -6px;
                    display: block;
                    right: 40px;
                    width: 20px;
                    height: 20px;
                    transform: rotate(315deg);
                    @include query($sp){
                        right: 20px;
                        transform: rotate(135deg);
                        margin-top: -12px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            &.open{
                span{
                    transform: rotate(135deg);
                    margin-top: -16px;
                    @include query($sp){
                        transform: rotate(315deg);
                        margin-top: -4px;
                    }
                }
            }
        }
        .acr_con{
            @include query($sp){
                display: none;
            }
        }
        h2{
            font-size: 24px;
            text-align: center;
            font-weight: 700;
            color: #fff;
            padding: 25px 0;
            @include query($sp){
                font-size: 20px;
            }
        }
        .inbox{
            padding: 55px 60px;
            @include query($sp){
                padding: 20px;
            }
        }
        p,th,td{
            font-size: 18px;
            @include query($sp){
                font-size: 14px;
            }
        }
        table{
            margin: 0;
            border-top: 1px solid #ccc;
            @include query($sp){

            }
        }
        th{
            font-weight: 700;
            width: 150px;
            border-bottom: 1px solid #ccc;
        }
        td{
            padding: 25px 20px;
            border-bottom: 1px solid #ccc;
        }
        @include query($sp){
            th,td{
                display: block;
                width: 100%;
                padding: 15px 0;
            }
            th{
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

.slick-next{
    background: url(../images/arr-gray.png) no-repeat center center !important;
    background-size: 16px;
    &::before{
        content: '';
        display: none;
    }
}

.slick-prev{
    background: url(../images/arr-gray-prev.png) no-repeat center center !important;
    background-size: 16px;
    &::before{
        content: '';
        display: none;
    }
}

.form_submit_confirm{
    @include flexbox(space-between, center);
    .btnback{
        border-radius: 40px;
        button,a{
            background: #00beca url(../images/prev.png) no-repeat left 20px center;
            background-size: 12px;
            border-radius: 40px;
        }
    }
    .btnnext{
        border-radius: 40px;
        button{
            background: #ff7e00 url(../images/next.png) no-repeat right 20px center;
            background-size: 12px;
            border-radius: 40px;
        }
    }
    .btnpage{
        margin: 0;
        button{
            
        }
        span{
            font-size: 18px;
        }
    }
}