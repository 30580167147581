@import "../components/mixins";

.nav-list {
	@include minquery($pc) {
		@include flexbox(center, center);
	}
	@include query($sp) {
		height: auto;
		padding: 0 3%;
		overflow: hidden;
	}
	&-item {
		@include query($sp) {
			border-bottom: 1px solid #ccc;
		}
		a {
			font-size: 16px;
			line-height: 1;
			color: #333;
			text-decoration: none;
			display: block;
			padding: 15px 20px;
			text-align: center;
			font-weight: 500;
			@include query($sp) {
				padding: 20px 0;
				text-align: center;
				font-size: 18px;
			}
			@include minquery($pc) {
				&:hover {
					opacity: 0.8;
					color: $cl;
				}
			}
		}
	}
}

.header_nav_dropdown{
	.nav-list{
		//IF WANT SET FULL BACKGROUND REMOVE COMMENT POSITION UNDER
		//position: relative;
		> li {
			&.dropdown{
				position: relative;
			}
		}
		.childmenu{
			display: none;
			overflow: hidden;
			@include minquery($pc) {
				position: absolute;
				left: 0;
				z-index: 9;
				background: #f1f1f1;
				min-width: 200px;
			}
			li{
				@include query($sp) {
					&:first-child {
						border-top: 1px solid #ccc;
					}
					&:last-child {
						border-bottom: none;
					}
				}
				a{
					text-align: left;
					padding: 15px 10px;
				}
			}
		}
		.megamenu{
			> a{
				@include query($sp) {
					position: relative;
					@include plusicon(#000);
				}
			}
			&_inside{
				width: 100%;
			}
			&_list{
				@include minquery($pc) {
					@include flexbox(space-between, center);
					//IF DONT WANT SET MAX WIDTH REMOVE UNDER
					max-width: 1000px;
					margin: 0 auto;
				}
			}
			&_col{
				@include minquery($pc) {
					flex: 0 0 25%;
					padding: 10px 20px;
				}
				@include query($sp) {
					li a{
						padding-left: 20px;
					}
				}
			}
			&_title{
				color: #000;
				font-size: 16px;
				display: block;
				line-height: 1;
				text-align: left;
				@include minquery($pc) {
					padding: 10px 0;
				}
				@include query($sp) {
					padding: 15px 10px;
				}
			}
		}
	}
}

// NAV TYPE SHOW DEFAULT
.navstate_show {
	.header_nav {
		.nav-list {
			// background: rgba($cl, 0.75);
			@include minquery($pc) {
        justify-content: space-between;
      }
		}
		&_inside{
			@include query($sp) {
				position: absolute;
				left: -100%;
				top: 0;
				padding: 0;
        opacity: 0;
				visibility: hidden;
				transition: all .3s ease;
			}
    }
    &.type2{
      .header_nav_inside{
        @include query($sp) {
          left: 0;
					top: -10vh;
          right: 0;
					transition-timing-function: cubic-bezier(-0.6, -0.28, 0.735, 0.045);
        }
      }
    }
    &.type3{
      .header_nav_inside{
        @include query($sp) {
          left: 0;
          right: 0;
          @include scaleX(0);
          transition: ease-in-out .4s;
        }
			}
			.nav-list{
				@include query($sp) {
					@include flexbox(center, center);
					flex-direction: row;
					flex-wrap: wrap;
					min-height: auto;
					background: #000;
					padding: 5vh 0 20vh;
					height: calc(100vh - 60px);
					@include query($q520) {
						height: calc(100vh - 44px);
					}
				}
				&-item{
					@include query($sp) {
						overflow: hidden;
						border: none;
						flex: 0 0 100%;
					}
					a{
						@include query($sp) {
							font-weight: bold;
							font-size: 24px;
							text-align: center;
							color: #fff;
						}
					}
				}
			}
    }
	}
	.icon-hamburger {
		@include minquery($pc) {
			display: none;
		}
	}
}

// NAV TYPE HIDE
.navstate_hide{
	overflow: hidden;
  .header_nav {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
		visibility: hidden;
		transition: all .2s ease-in-out;
		.nav-list {
      justify-content: space-between;
      flex-wrap: wrap;
      &-item{
        flex: 0 0 100%;
        overflow: hidden;
        margin: 30px 0;
        border: 0;
        a{
          font-size: 30px;
          font-weight: bold;
          color: #fff;
          padding: 0;
          padding-top: 5px;
        }
      }
    }
		&_inside{
      position: relative;
			z-index: 9;
    }
		&.type1{
			@include flexbox(space-between, center);
			&:before {
				content:'';
				position: absolute;
				background: rgba($cl, .9);
				transition: all .5s ease-in-out;
				width: 100%;
				height: 100vh;
				left: 0;
				top: 0;
				z-index: 0;
				@include scale(0.5);
			}
		}
    &.type2{
			left: inherit;
			right: -35%;
			width: 35%;
			background: $cl;
			@include transition_cubic(all, $duration:0.5s, $effect:cubic-bezier(0,.2,.17,.98));
			@include flexbox(space-between, center);
			@include minquery($pc) {
				max-width: 400px;
			}
			@include query($sp) {
				width: 100%;
				right: -100%;
			}
			.header_nav_inside{
				&:before {
					content:'';
					position: fixed;
					background: rgba($cl, .9);
					transition: all .5s ease-in-out;
					padding: 0;
					width: 100%;
					height: 100vh;
					left: 0;
					top: 0;
					z-index: -1;
				}
			}
		}
		&.type3{
			@include transition_cubic(all, $duration:0.5s, $effect:cubic-bezier(0,.2,.17,.98));
			@include flexbox(space-between, center);
			background: rgba($cl, .9);
			@include scale(1.1);
		}
	}
	&.menu_fixed{
		header{
			.icon-hamburger.type1,
			.icon-hamburger.type2{
				position: fixed;
				right: 0;
				top: 0;
			}
		}
	}
}