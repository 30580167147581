@import "../components/define";

// @mixin helvetica($fw:false) {
//     @if ($fw) {
//         @font-face {
//             font-family: Helbold;
//             src: url(../font/Helvetica-Condensed-Bold.otf);
//         }
//         font-family: Helbold !important;
//         font-weight: bold;
//     }
//     @else {
//         @font-face {
//             font-family: Helnormal;
//             src: url(../font/Helvetica-Condensed.otf);
//         }
//         font-family: Helnormal !important;
//         font-weight: normal;
//     }
// }

@mixin lato {
    @import url('https://fonts.googleapis.com/css?family=Lato:400,400i,500,700&subset=vietnamese');
    font-family: 'Lato' !important;
}

@mixin roboto {
    @import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700&subset=vietnamese');
    font-family: 'Roboto' !important;
}

@mixin open_sans {
    @import url('https://fonts.googleapis.com/css?family=Open Sans:400,400i,600,700&subset=vietnamese');
    font-family: 'Open Sans' !important;
}

@mixin pt_sans {
    @import url('https://fonts.googleapis.com/css?family=PT Sans:400,400i,600,700&subset=vietnamese');
    font-family: 'PT Sans' !important;
}

@mixin montserrat {
    @import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,600&subset=vietnamese');
    font-family: 'Montserrat' !important;
}

@mixin oswald {
    @import url('https://fonts.googleapis.com/css?family=Oswald:400,500,700&subset=vietnamese');
    font-family: 'Oswald', Helnormal !important;
}

@mixin quicksand {
    @import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&display=swap');
    font-family: 'Quicksand', sans-serif;
}

@mixin fontnoto {
    font-family: 'Noto Sans JP';
}

@mixin fontpinyo {
    font-family: 'Pinyon Script', 'Playfair Display';
}

@mixin gothic {
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, 'MS UI Gothic', sans-serif !important;
}

@mixin gothic_meiryo {
    font-family: "Meiryo", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, 'MS UI Gothic', sans-serif;
}

@mixin gothicMedium {
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, 'MS UI Gothic', sans-serif !important;
}

@mixin mincho {
    font-family: "Yu Mincho", "YuMincho", "YuMin-medium", 'ヒラギノ明朝 ProN', 'Hiragino Mincho ProN', '游明朝', '游明朝体', 'MS 明朝', 'MS Mincho', HiraMinProN-W3, 'TakaoEx明朝', TakaoExMincho, 'MotoyaLCedar', 'Droid Sans Japanese', serif !important;
    //antialiase for MS font if not work. 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: rotate(0.05deg);
    transform: rotate(0.05deg);
}

@mixin jpmincho {
    font-family: "jpfontBold", "Yu Mincho", "YuMincho", "YuMin-medium", 'ヒラギノ明朝 ProN', 'Hiragino Mincho ProN', '游明朝', '游明朝体', 'ＭＳ 明朝', 'MS Mincho', HiraMinProN-W3, 'TakaoEx明朝', TakaoExMincho, 'MotoyaLCedar', 'Droid Sans Japanese', serif !important;
    font-weight: 500;
}

// @font-face {
//     font-family: 'Noto Sans JP';
//     font-style: normal;
//     font-weight: 100;
//     src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format('woff2'),
//             url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format('woff'),
//             url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format('opentype');
// }
// @font-face {
//     font-family: 'Noto Sans JP';
//     font-style: normal;
//     font-weight: 300;
//     src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format('woff2'),
//             url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format('woff'),
//             url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format('opentype');
// }

// @font-face {
//     font-family: 'Noto Sans JP';
//     font-style: normal;
//     font-weight: 400;
//     src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format('opentype');
// }
// @font-face {
//     font-family: 'Noto Sans JP';
//     font-style: normal;
//     font-weight: 500;
//     src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format('opentype');
// }
// @font-face {
//     font-family: 'Noto Sans JP';
//     font-style: normal;
//     font-weight: 700;
//     src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format('opentype');
// }
// @font-face {
//     font-family: 'Noto Sans JP';
//     font-style: normal;
//     font-weight: 900;
//     src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format('woff2'),
//         url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format('woff'),
//         url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format('opentype');
// }
//END FONT


//Helvetica
// @font-face {
//     font-family: 'Helvetica Neue Bold';
//     font-style: normal;
//     src: url('../font/HelveticaNeueBold.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Neue Medium Italic';
//     font-style: normal;
//     src: url('../font/HelveticaNeueMediumItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Neue Black Italic';
//     font-style: normal;
//     src: url('../font/HelveticaNeueBlackItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica UltraLight';
//     font-style: normal;
//     src: url('../font/Helvetica25UltraLight.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica UltraLight Italic';
//     font-style: normal;
//     src: url('../font/Helvetica26UltraLightItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Thin';
//     font-style: normal;
//     src: url('../font/Helvetica35Thin.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Thin Italic';
//     font-style: normal;
//     src: url('../font/Helvetica36ThinItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Light';
//     font-style: normal;
//     src: url('../font/Helvetica45Light.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Light Italic';
//     font-style: normal;
//     src: url('../font/Helvetica46LightItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Roman';
//     font-style: normal;
//     src: url('../font/Helvetica55Roman.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Roman Italic';
//     font-style: normal;
//     src: url('../font/Helvetica56RomanItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Medium Regular';
//     font-style: normal;
//     src: url('../font/Helvetica65Medium.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Bold Italic';
//     font-style: normal;
//     src: url('../font/Helvetica76BoldItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Bold Heavy';
//     font-style: normal;
//     src: url('../font/Helvetica85Heavy.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Bold Heavy Italic';
//     font-style: normal;
//     src: url('../font/Helvetica86HeavyItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Helvetica Black';
//     font-style: normal;
//     src: url('../font/Helvetica95Black.woff') format('woff');
// }