// INDEX OF ANIMATIONS
// This is a port of Den Eden's Animate.css, converted for SASS.
// Source: https://daneden.me/animate/
//
// @import this index into your project.
// Comment out categories below to remove them from your project.
// Or, @import specific partials (e.g. @import "_attention/_bounce.scss";
//
//-----------------------------------------------------------------------

// Always required
@import
  "./_properties";

// Import the animations
@import
  // "./_attention-seekers/attention-seekers.scss",
  // "./_bouncing-entrances/bouncing-entrances.scss",
  // "./_bouncing-exits/bouncing-exits.scss",
  "./_fading-entrances/fading-entrances.scss",
  // "./_fading-exits/fading-exits.scss",
  // "./_flippers/flippers.scss",
  // "./_lightspeed/lightspeed.scss",
  // "./_rotating-entrances/rotating-entrances.scss",
  // "./_rotating-exits/rotating-exits.scss",
  "./_sliding-entrances/sliding-entrances.scss"
  // "./_sliding-exits/sliding-exits.scss",
  // "./_specials/specials.scss",
  // "./_zooming-entrances/zooming-entrances.scss",
  // "./_zooming-exits/zooming-exits.scss";
      