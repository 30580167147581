@import "../components/mixins";

.header{
  &_top{
    padding: 20px 0;
    @include query(520){
      padding: 3% 0;
    }
    &_logo{
      position: relative;
      z-index: 1;
      a{
        line-height: 0;
      }
      @include query($sp) {
        flex: 0 0 40%;
      }
      @include query(520){
        flex: 0 0 30%;
      }
    }
    &_icon{
      flex: 1 1 auto;
      @include flexbox(flex-end, center);
    }
    &_menu{
      .header_nav{
        flex: 1;
        position: inherit;
      }
    }
  }
  &_nav{
    position: relative;
    z-index: 98;
    .header_nav_inside{
      padding-right: 0 !important;
      @include minquery($pc) {
        min-width: inherit;
      }
      @include query($sp){
        padding: 80px 0 !important;
        background: #fff;
      }
    }
    @include query($sp){
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
  .icon{
    margin-left: 5px;
    @include query($sp) {
      margin-left: 10px;
    }
    &_inside, &-hamburger {
      position: relative;
      cursor: pointer;
      @include flexbox(center, center);
      width: 57px;
      height: 57px;
      background: $cl;
      transition: all .3s;
      background: rgb(0,144,202);
      background: linear-gradient(90deg, rgba(0,144,202,1) 0%, rgba(0,190,202,1) 100%); 
      @include minquery($pc) {
        &:hover {
          opacity: 0.7;
        }
      }
      @include query($q520) {
        width: 44px;
        height: 44px;
      }
    }
    &-hamburger{
      z-index: 99;
      .icon-bar {
        span {
          left: 0;
          height: 2px;
          position: absolute;
          background: #fff;
          display: inline-block;
          width: 100%;
          border-radius: 3px;
          @include transition(.4s);
          &:first-child {
            top: 0;
          }
          &:nth-child(2) {
            @include centerver;
          }
          &:last-child {
            bottom: 0;
          }
        }
      }
      @include query($sp){
        .icon-txt{
          display: none !important;
        }
      }
    }
    &-img, &-bar{
      position: relative;
      @include flexbox(center, center);
      height: 38%;
      width: 56%;
      img{
        max-width: 30px;
      }
    }
    &-txt{
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
      text-align: center;
      @include centerhor();
      bottom: 0;
      @include query($sp) {
        font-size: 11px;
      }
      @include query(420) {
        font-size: 9px;
      }
    }
    
    &-mail{
      width: 180px;
      margin-left: 20px;
      @include query($sp){
        margin-left: 0;
        margin-right: 20px;
      }
      @include query(520){
        margin-right: 5px;
        width: 160px;
      }
      .icon_inside{
        width: 180px;
        border-radius: 40px;
        height: 60px;
        @include query(520){
          height: 44px;
          width: 160px;
        }
      }
      .icon-txt{
        font-size: 16px;
        color: #fff;
        bottom: 20px;
        font-weight: bold;
        @include query(520){
          font-size: 14px;
        }
      }
      @include minquery($pc){
        .icon-img{
          display: none;
        }
      }
      @include query($sp){
        .icon_inside{
          padding-left: 20px;
          padding-right: 10px;
        }
        .icon-img{
          width: 30%;
          max-width: 30px;
        }
        .icon-txt{
          position: relative;
          bottom: inherit;
          padding: 0;
        }
      }
    }
  }
  .hastext_true {
    .icon-img, .icon-bar {
      top: -6px;
      img{
        max-height: 25px;
      }
      @include query($q520) {
        img{
          max-width: 24px;
        }
      }
    }
  }
  &-fixed{
    position: fixed;
    width: 100%;
    z-index: 97;
  }
}

.menu-open {
  position: relative;
  &.navstate_show{
    .header_nav {
      &.show{
        .header_nav_inside{
          left: 0;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .icon-hamburger{
      .icon-bar{
        span {
          &:nth-child(1) {
            transform: translateY(9px) rotate(-225deg);
            @include query($q520) {
              transform: translateY(7px) rotate(-225deg);
            }
          }
          &:nth-child(2) {
            transform: translateY(0) rotate(225deg);
            width: 100%;
          }
          &:nth-child(3) {
            opacity: 0;
          }
        }
      }
    }
  }
}